<template>
        <main-template>
            <template v-slot:headerLottieDark>
                <dotlottie-player :src="getDarkLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:headerLottieLight>
                <dotlottie-player :src="getLightLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:header>HQ</template>
            <template v-slot="slotProps">
            <fetch-siren-root ref="sirenRoot" :linkName='linkName' :link="link">

                <div slot-scope="{ response }" class="flex flex-grow overflow-hidden">

                        <div class="flex w-full overflow-hidden">
                            <div class="p-2 h-full">
                                <hq-navigation :links="slotProps.links"></hq-navigation>
                            </div>
                            <div class="flex w-full h-full p-2 overflow-hidden">
                                <div class="rounded-xl bg-v3-base-500 w-full h-full margin-10 dark:bg-v3-gray-900 overflow-hidden" :class="{'grid grid-cols-3': (details || loadingDetails) && !hideTable}">
                                    <loading v-if="!response"></loading>
                                    <template v-else>
                                        <div class="p-2 overflow-y-auto h-full" :class="{'hidden': hideTable}">
                                            <sage-description-pagination ref="voucherList" :response="response" :entities="getSages(response)" @setDetails="showDetails" @setLoading="setLoading" @altViewClicked="altViewClicked" @reloadItems="reloadItems">
                                                <template v-slot:title-buttons>
                                                    <SageNavigationButton :links="slotProps.links"/>
                                                </template>
                                                <template v-slot:additional-buttons>
                                                    <template v-for="(action, index) in response.body.actions.filter(action => action.name === 'add-new-sage-description')">
                                                        <form-action :response="details" additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :redirectOnSuccess="true" :onSuccessPath="$router.currentRoute.fullPath"></form-action>
                                                    </template>
                                                </template>
                                            </sage-description-pagination>
                                        </div>
<!--                                        <SingleSalesInvoiceDetails ref="voucherDetails" :details="details" :loadingDetails="loadingDetails" :hideTable="hideTable" @setLoading="setLoading" @getDetails="getDetails" @hideTable="toggleHideTable"/>-->
                                    </template>
                                </div>
                            </div>
                        </div>
                </div>
            </fetch-siren-root>
            </template>
        </main-template>
</template>

<script>
import FetchSirenRoot from '@/components/FetchSirenRoot';
import Loading from '@/components/Loading';
import MainTemplate from '@/v3/templates/MainTemplate';
import {mapGetters} from "vuex";
import Siren from "super-siren";
import HqNavigation from "@/v3/components/navigation/HqNavigation.vue";
import SingleSalesInvoiceDetails from "@/v3/pages/SingleSalesInvoiceDetails.vue";
import FormAction from "@/v3/components/forms/FormAction.vue";
import CreditNotesPagination from "@/v3/components/pagination/CreditNotesPagination.vue";
import SageTaxCodesPagination from "@/v3/components/pagination/SageTaxCodesPagination.vue";
import SageDescriptionPagination from "@/v3/components/pagination/SageDescriptionPagination.vue";
import SageNavigationButton from "@/v3/components/SageNavigationButton.vue";

export default {
    inject: ["$validator"],
    components: {
        SageNavigationButton,
        SageDescriptionPagination,
        FormAction,
        SingleSalesInvoiceDetails,
        HqNavigation,
        FetchSirenRoot,
        Loading,
        MainTemplate,

    },
    data() {
        return {
            slotProps: null,
            users: null,
            details: null,
            detailsOpen: false,
            loadingDetails: false,
            entities: null,
            hideTable: false
        }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser"
        }),
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        }

    },
    mounted() {
        let follow = Siren.Helper.follow;
    },
    methods: {
        getSages(response) {
            if (!response) {
                return null;
            }
            return response.body.entities.filter(entity => entity.rels.contains('sage_description'));
        },
        showDetails(details){
            this.$setLaravelValidationErrorsFromResponse('');
            this.details = details;
            this.loadingDetails = false;
        },
        setLoading(){
            this.$refs.voucherDetails.editing = false;
            this.$refs.voucherDetails.editingDayPays = false;
            this.$refs.voucherDetails.editingQa = false;
            this.$refs.voucherDetails.tabOpen = 'invoice-items';
            this.details = null;
            this.loadingDetails = true;
        },
        getDetails(entity){
            this.$refs.voucherList.getDetails(entity);
        },
        toggleHideTable(){
            this.hideTable = !this.hideTable;
        },
        altViewClicked(){
            this.details = null;
        },
        reloadItems(res){
            this.$refs.sirenRoot.load();
        },
        getDarkLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=hq_dark"
        },
        getLightLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=hq_light"
        }
    }
}
</script>

